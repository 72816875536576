.DashLay {
    margin: 20px 20px 20px 300px;
}

.dash-tile {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 5px;
    gap: 20px;
}

.ltsv-label {
    width: 22px;
    height: 22px;
    border-radius: 7px;
    background-color: #e4f0f2;
}

.rtsv-label {
    width: 22px;
    height: 22px;
    border-radius: 7px;
    background-color: #c8cfb7;
}

.tsv-data-filter {
    border-radius: 20px;
    padding: 8px 14px;
    background-color: #f8f8f9;
    border-radius: 40px;
    display: flex;
    justify-content: center;
}

.doghnut-label-dot {
    width: 17px;
    height: 17px;
    border-radius: 6px;
}

.member-text {
    font-size: 16px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
}

.dash-nav-prf {
    border-radius: 50px;
    padding: 10px 25px;
    background-color: #fee4d1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.dash-nav-search,
.dash-nav-notify,
.dash-nav-logout {
    border-radius: 50px;
    padding: 10px 25px;
    background-color: #fee4d1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.dashNav-nootify-new {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100% !important;
    background-color: #fee4d1 !important;
    border-radius: 50px !important;
    padding: 0 40px !important;
}

.dash-nav-search:hover,
.dash-nav-notify:hover,
.dash-nav-logout:hover {
    background-color: #fcc8a3;
}

.dash-nav-prf {
    border-radius: 20px;
    cursor: default;
}
.cursor-pointer {
    cursor: pointer;
}
/* Tile */
.dash_few_box_name {
    font-weight: 500;
    margin-bottom: 4px;
}

.dash-tile > div {
    padding: 20px 15px;
    border-radius: 20px;
    width: 100%;
    /* aspect-ratio: 2/2; */
}

.dash-tile > div:nth-child(odd) {
    background-color: #c8cfb7;
}

.dash-tile > div:nth-child(even)
 {
    background-color: #e4f0f2;
}

/* Charts */
.dash-charts {
    border-radius: 20px;
    padding: 20px;
    padding-bottom: 36px;
    height: 100%;
    box-shadow: 0px -1px 15px 7px rgba(0, 0, 0, 5%);
    -webkit-box-shadow: 0px -1px 15px 7px rgba(0, 0, 0, 5%);
    -moz-box-shadow: 0px -1px 15px 7px rgba(0, 0, 0, 5%);
}

.dash-charts-doghnut {
    height: 30rem;
    padding-bottom: 4rem;
}
.team-volume .common-input-container{
margin-bottom: 0 !important;
}
.team-volume .common-input-container .common-input-div {
    height: 54px;
    margin-top: 0;
}
.team-volume .button-team-volume {
    margin: 0;
    padding: 14px;
}
.prf-name-sec {
    padding: 0 15px;
}

/* Media Query */

@media (max-width: 650px) {
    .dash-nav-prf {
        border-radius: 50px;
        padding: 10px 25px;
        background-color: #fee4d1;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        height: 100%;
        cursor: pointer;
    }

    .dash-nav-search,
    .dash-nav-notify,
    .dashNav-nootify-new,
    .dash-nav-logout {
        padding: 8px !important;
        background-color: transparent !important;
    }

    .dash-nav-search:hover,
    .dash-nav-notify:hover,
    .dash-nav-logout:hover {
        padding: 0;
        background-color: transparent;
        color: #b9652a;
    }
}

@media (min-width: 1400px) {
    .dash_few_box_name{
        font-size: 18px;
    }
    .last_chart_pb_sm {
        /* gap: 10px; */
        margin-top: 10px;
    }

    .first_chart_pb_sm > div,
    .last_chart_pb_sm > div {
        padding: 0 14px !important;
    }
    .dash-charts {
        padding: 28px;
        padding-bottom: 40px;
    }
}

@media (max-width: 1435px) {
    .dash-nav-logout--cnt {
        display: none;
    }
}

@media (max-width: 1200px) {
    .dash-tile {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr !important;
    }
}

@media (max-width: 992px) {
    .DashLay {
        margin: 20px;
    }

    .dash-nav {
        margin: 20px 20px 0 20px;
    }

    .dash-nav-right {
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }

    .dash-nav-logout--cnt {
        display: none;
    }
}

@media (max-width: 778px) {
    .dash-tile {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 10px;
    }
}

@media (max-width: 768px) {
    .dash-tile {
        display: grid;
        grid-template-columns: 1fr 1fr !important;
    }
    .dash-tile > div {
        aspect-ratio: inherit;
    }
    .dog-nut_container-sm {
        padding: 0 13px;
        max-width: 186px;
    }
}

@media (max-width: 500px) {
    .dash-tile {
        display: grid;
        grid-template-columns: 1fr !important;
    }
    .doghnut-label-p .col-auto {
        width: 100%;
    }
}



