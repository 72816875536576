.profile_sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: #e4f0f2; */
}
.profile_sec-info {
    color: #9b5731;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.profile_sec-details {
    padding: 1.5rem 10rem;
}

.form-new-select {
    width: 100%;
    height: 100%;
    background: none;
    padding: 10px;
    border: none;
    /* margin-left: -15px; */
}
.form-new-select:focus {
    outline: none;
    border: none;
}

.form-new-select:disabled,
.form-new-input:disabled {
    /* background-color: #e7e7e78e !important; */
    /* color: #000000; */
    /* width: 107%; */
    /* margin-left: -20px; */
    /* padding-left: 22px; */
    /* opacity: 0.7; */
    cursor: not-allowed;
}
